import axios from 'axios';
import {
  addAggregatorUrl,
  addBankDetailsUrl,
  addCashierBankDetailsUrl,
  addOutboundCategoryUrl,
  addProductCategoryUrl,
  addTeamMembersUrl,
  aggregatorMemberPayoutUrl,
  ammbanUrl,
  bankNameUrl,
  banksListUrl,
  baseQueryUrl,
  baseUrl,
  bonusBalanceUrl,
  bonusTransactionsUrl,
  bonusWithdrawUrl,
  bvn_nin_url,
  bvn_otp_url_confirm,
  cacUrl,
  cashierBankNameUrl,
  cashpointAlternateAccountURL,
  cashpointBaseUrl,
  cashpointCustomersUrl,
  cashpointDeactivateUrl,
  cashpointUrl,
  changePasswordUrl,
  completeRegUrl,
  complianceLivelinessVideoUrl,
  confirmUSSDUrl,
  contactSupportUrl,
  createDisputeUrl,
  createTnxUrl,
  currentUserUrl,
  deleteBeneficiariesUrl,
  deletePaymentGroupUrl,
  disputeTypeUrl,
  downlinesUrl,
  dropinUrl,
  fetchCashierBanksUrl,
  forgotPasswordInitialUrl,
  generateCashpointTnxHistoryUrl,
  getAllCategoriesUrl,
  getAllMerchantPaymentGroupBeneficiariesUrl,
  getAllMerchantPaymentGroupUrl,
  getAlternateAccountFeesURL,
  getCategoryListUrl,
  getFailedWebOrderTnxsUrl,
  getInboundTnxsCountOnlyUrl,
  getInboundTnxsCountUrl,
  getLivelinessKeyUrl,
  getMerchantCurrentLimitUrl,
  getMerchantProductCategoryUrl,
  getMerchantRolesListUrl,
  getMerchantTeamListUrl,
  getOnePaymentGroup,
  idCardUploadUrl,
  industryUrl,
  internationalPassportUrl,
  loanUrl,
  loginUrl,
  lookupCashpointUrl,
  merchantAddBulkBeneficiaryUrl,
  merchantAddBulkPaymentGroup,
  merchantPayGroupUrl,
  merchantUrl,
  otherUrl,
  paymentStatusUrl,
  proofOfAddressUrl,
  referralUrl,
  referralWithdrawUrl,
  resetPwrdUrl,
  reverseTransactionUrl,
  sandboxAticvate,
  sendCrashReportUrl,
  startSignupUrl,
  storefrontWaitlistUrl,
  submitCustomInboundUrl,
  submitIncomeCategoryUrl,
  submitMerchantCurrentLimitUrl,
  takeSelfieUrl,
  transactionDetailUrl,
  updateTeamMemberUrl,
  updateWebUrl,
  uploadBeneficiariesExcelListUrl,
  uploadFileStatusUrl,
  uploadFileToS3Url,
  validateChangePasswordUrl,
  webhookRepushUrl,
  webhookUrl,
} from './urls';

let d = new Date();
let requestId = d.getTime();
let publicKey = process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_PUBLIC_KEY : process.env.REACT_APP_PUBLIC_KEY_PROD;
let accessToken = localStorage.getItem('v_pay_token');

axios.defaults.headers['publicKey'] = publicKey;
axios.defaults.headers['requestId'] = requestId;

if (accessToken) {
  axios.defaults.headers.accessToken = accessToken;
  axios.defaults.headers['b-access-token'] = accessToken;
}

export const startSignup = (data, appCheck) => {
  return axios({
    method: 'POST',
    url: startSignupUrl,
    data,
    headers: {
      'platform-type': 'web',
      appcheck_token: appCheck,
    },
  });
};

export const completeSignup = (data, appCheck) => {
  return axios({
    method: 'POST',
    url: completeRegUrl,
    data,
    headers: {
      'platform-type': 'web',
      appcheck_token: appCheck,
    },
  });
};

export const editMerchant = (data, id) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}${id}/edit`,
    data,
  });
};

export const fetchIndustry = () => {
  return axios({
    method: 'GET',
    url: `${industryUrl}`,
  });
};

export const fetchMerchantBalance = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/wallet/balance`,
  });
};

export const getCashpointInfo = (id) => {
  return axios({
    method: 'GET',
    url: `${cashpointCustomersUrl}${id}/show`,
  });
};

export const fetchMerchantPayouts = (id, pageNumber, startDate, endDate) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/payouts/show`,
    params: {
      page: pageNumber ?? '1',
      startdate: startDate,
      enddate: endDate,
    },
  });
};

export const fetchMemberPayouts = (id, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${aggregatorMemberPayoutUrl}/${id}/payouts/show`,
    params: {
      page: pageNumber ?? '1',
    },
  });
};

export const printStatement = (id, data, key) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}txn/${id}`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const merchantLogin = (data) => {
  return axios({
    method: 'POST',
    url: loginUrl,
    data,
    headers: {
      'platform-type': 'web',
    },
  });
};

export const getCurrentUser = () => {
  return axios({
    method: 'GET',
    url: currentUserUrl,
  });
};

export const getCurrentUserFromMobileApp = (token) => {
  return axios({
    method: 'GET',
    url: currentUserUrl,
    headers: {
      accessToken: token,
      'b-access-token': token,
    },
  });
};

export const fetchMerchantCustomers = (id, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/customers/show`,
    params: {
      page: pageNumber ?? '1',
    },
  });
};

export const fetchMerchantTransactions = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/transactions/show`,
  });
};

export const fetchMerchantCashpoints = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/cashpoints/show`,
  });
};

export const fetchMerchant = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/show`,
  });
};

export const fetchMerchantKey = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/privatekey`,
  });
};

export const addCashpoint = (data) => {
  return axios({
    method: 'POST',
    url: cashpointUrl,
    data,
  });
};

export const fetchMerchantCustomersCashpoints = (id, pageNumber, startDate, endDate) => {
  return axios({
    method: 'POST',
    url: `${cashpointCustomersUrl}${id}/customers/show`,
    // data,
    params: {
      page: pageNumber ?? '1',
      startdate: startDate,
      enddate: endDate,
    },
  });
};

export const contactSupport = (data, appCheck) => {
  return axios({
    method: 'POST',
    url: contactSupportUrl,
    data,
    headers: {
      'platform-type': 'web',
      appcheck_token: appCheck,
    },
  });
};

export const changePassword = (data) => {
  return axios({
    method: 'POST',
    url: changePasswordUrl,
    data,
  });
};

export const validateChangePassword = (data) => {
  return axios({
    method: 'POST',
    url: validateChangePasswordUrl,
    data,
  });
};

export const resetPassword = (data, appCheck) => {
  return axios({
    method: 'POST',
    url: resetPwrdUrl,
    data,
    headers: {
      'platform-type': 'web',
      appcheck_token: appCheck,
    },
  });
};

export const forgotPasswordValidateEmail = (data) => {
  return axios({
    method: 'POST',
    url: forgotPasswordInitialUrl,
    data,
  });
};

export const verifyBankDetails = (data) => {
  return axios({
    method: 'POST',
    url: bankNameUrl,
    data,
  });
};

export const verifyCashierBankDetails = (data) => {
  return axios({
    method: 'POST',
    url: cashierBankNameUrl,
    data,
  });
};

export const addBankDetails = (data) => {
  return axios({
    method: 'POST',
    url: addBankDetailsUrl,
    data,
  });
};

export const deactivateCashpoint = (data) => {
  return axios({
    method: 'POST',
    url: cashpointDeactivateUrl,
    data,
  });
};

export const fetchBanks = () => {
  return axios({
    method: 'GET',
    url: banksListUrl,
  });
};

export const requeryTransaction = (key, ref) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}wallet/requery/transaction/${ref}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchBeneficiaries = (key) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}wallet/beneficiary/show`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};
export const walletWithdraw = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}wallet/withdraw`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const referralWithdraw = (key, data) => {
  return axios({
    method: 'POST',
    url: `${referralWithdrawUrl}`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const walletTransfer = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}wallet/transfer`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const getUtilityPlans = () => {
  return axios({
    method: 'GET',
    url: `${baseQueryUrl}billing/utility/plans/show`,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const buyUtility = (data) => {
  return axios({
    method: 'POST',
    url: `${baseQueryUrl}billing/buy/utility`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getCablePlans = () => {
  return axios({
    method: 'GET',
    url: `${baseQueryUrl}billing/cabletv/plans/show`,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const buyCable = (data) => {
  return axios({
    method: 'POST',
    url: `${baseQueryUrl}billing/buy/cabletv`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getServicePlans = () => {
  console.log(`${baseQueryUrl}billing/mno/list/show`);
  return axios({
    method: 'GET',
    url: `${baseQueryUrl}billing/mno/list/show`,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const buyAirtime = (data) => {
  return axios({
    method: 'POST',
    url: `${baseQueryUrl}billing/buy/airtime`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const verifyCable = (smartcard_number, data) => {
  // const data = {
  //   "type": "cable",
  //   "variation_id": "dstv-yanga",
  //   smartcard_number,
  //   "service_id": "dstv"
  // }
  return axios({
    method: 'POST',
    url: `${baseQueryUrl}billing/verify/customer`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getDataPlansFor = (network) => {
  return axios({
    method: 'GET',
    url: `${baseQueryUrl}billing/data/plans/bynetwork/${network}`,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const buyData = (data) => {
  return axios({
    method: 'POST',
    url: `${baseQueryUrl}billing/buy/data`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const addBeneficiary = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}wallet/beneficiary/add`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchCodes = (key, type) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}wallet/transfer/${type}/codes/show`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchTranferFees = (key, id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}wallet/transfer/fees/${id}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchWalletPrefix = (key, id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}wallet/prefix/${id}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const requestOTP = (key) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}pin/requestOTP`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const resetForgotPin = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/forgetpin`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const forgotPin = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/forgetpin`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const forgetPinInitial = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/forgetpin/initial`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const resetPin = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/resetpin`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const validatePinReset = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/resetpin/initial`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const initialPinSet = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/set`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const authorizePin = (key, data) => {
  return axios({
    method: 'POST',
    url: `${merchantUrl}pin/authorise`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const toggleFundDestination = (id) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${id}/funddestination`,
  });
};

export const toggleKeyMode = (key) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}api/v1/webintegration/togglemode`,
    headers: {
      publicKey: key,
    },
  });
};

export const getRecieptInfo = (merchantId, transactionId) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${merchantId}/payouts/show/json/${transactionId}`,
  });
};
export const getRecieptPdf = (merchantId, transactionId) => {
  return axios({
    method: 'GET',
    url: `${merchantUrl}${merchantId}/payouts/show/pdf/${transactionId}`,
    responseType: 'blob',
  });
};

export const verifyBVN_NIN = (key, data) => {
  return axios({
    method: 'POST',
    url: bvn_nin_url,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const confirmBVN_NIN = (key, data) => {
  return axios({
    method: 'POST',
    url: bvn_otp_url_confirm,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const uploadID = (data, key) => {
  return axios({
    method: 'POST',
    url: idCardUploadUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const proofOfAddressUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: proofOfAddressUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const ammbanUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: ammbanUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const cacUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: cacUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const otherUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: otherUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const internationalPassportUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: internationalPassportUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const complianceVideoUpload = (data, key) => {
  return axios({
    method: 'POST',
    url: complianceLivelinessVideoUrl,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const uploadFile = (data) => {
  return axios({
    method: 'POST',
    url: 'https://api.cloudinary.com/v1_1/okeycj1/image/upload',
    data,
  });
};

export const merchantReferral = (mid) => {
  return axios({
    method: 'GET',
    url: `${referralUrl}${mid}/referrals`,
    headers: {
      // publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const fetchCashierBanks = () => {
  return axios({
    method: 'GET',
    url: fetchCashierBanksUrl,
  });
};

export const addCashierBankDetails = (data) => {
  return axios({
    method: 'POST',
    url: addCashierBankDetailsUrl,
    data,
  });
};

export const uploadCashierProfilePicture = (id, data) => {
  return axios({
    method: 'POST',
    url: `${cashpointBaseUrl}${id}/profileimage/upload`,
    data,
  });
};

export const addWebhook = (data, key) => {
  return axios({
    method: 'POST',
    url: `${webhookUrl}`,
    data,
    headers: {
      publicKey: key,
    },
  });
};

export const repushWebhook = (merchantID, merchantTnxId) => {
  return axios({
    method: 'PUT',
    url: `${webhookRepushUrl}/${merchantID}/webhook/push/${merchantTnxId}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const bonusBalance = (type, key) => {
  return axios({
    method: 'GET',
    url: `${bonusBalanceUrl}?type=${type}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const aggregatorDownline = (key, page = 1) => {
  return axios({
    method: 'GET',
    url: `${downlinesUrl}?page=${page}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const addAggregatorService = (data, key) => {
  return axios({
    method: 'POST',
    url: `${addAggregatorUrl}`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const bonusTransactionsService = (key, pageNumber, paymentType, actor) => {
  return axios({
    method: 'GET',
    url: `${bonusTransactionsUrl}?type=${actor}&page=${pageNumber}&paymenttype=${paymentType}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const bonusWithdrawService = (key, data) => {
  return axios({
    method: 'POST',
    url: `${bonusWithdrawUrl}`,
    data,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const updateWeb = (key) => {
  return axios({
    method: 'PUT',
    url: `${updateWebUrl}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const updateSandbox = (key) => {
  return axios({
    method: 'PUT',
    url: `${sandboxAticvate}`,
    headers: {
      publicKey: key,
      requestId,
      accessToken,
    },
  });
};

export const getDisputeType = () => {
  return axios({
    method: 'GET',
    url: `${disputeTypeUrl}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const createDispute = (data) => {
  return axios({
    method: 'POST',
    url: `${createDisputeUrl}`,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getTransactionChallenge = (key, transactionId) => {
  return axios({
    method: 'GET',
    url: `${baseUrl}service/demo/v1/query/aggregator/challenge/transaction/${transactionId}/question`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const setChallengeAnswer = (data, transactionId, key) => {
  return axios({
    method: 'POST',
    url: `${baseUrl}service/demo/v1/query/aggregator/challenge/transaction/${transactionId}/answer`,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const reverseTransactionService = (id, key) => {
  return axios({
    method: 'POST',
    url: `${reverseTransactionUrl}/${id}/reverse`,
    headers: {
      publicKey: key,
    },
  });
};

export const uploadSelfie = (data) => {
  return axios({
    method: 'PUT',
    url: takeSelfieUrl,
    data,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getTransactionDetailService = (id) => {
  return axios({
    method: 'GET',
    url: `${transactionDetailUrl}/${id}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const confrimUSSD = (id) => {
  return axios({
    method: 'GET',
    url: `${confirmUSSDUrl}/${id}/confirm/ussd`,
    headers: {
      publicKey,
      requestId,
      accessToken,
    },
  });
};

export const getCashierTransactionDetailService = (id) => {
  return axios({
    method: 'GET',
    url: `${cashpointCustomersUrl}customer/${id}/show`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getCashierMerchantDetails = (data) => {
  return axios({
    method: 'POST',
    url: createTnxUrl,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getMerchantPaymentGroups = (key, pageNumber) => {
  return axios({
    method: 'GET',
    url: getAllMerchantPaymentGroupUrl,
    params: {
      page: pageNumber ?? '1',
    },
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const getOneMerchantPaymentGroup = (key, groupId) => {
  return axios({
    method: 'GET',
    url: `${getOnePaymentGroup}${groupId}/show`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const getPaymentGroupCurrentBeneficiaries = (key, group, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${getAllMerchantPaymentGroupBeneficiariesUrl}`,
    params: {
      page: pageNumber ?? '1',
      group: group,
    },
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const uploadFileStatus = (key, group, merchant) => {
  return axios({
    method: 'GET',
    url: `${uploadFileStatusUrl}`,
    params: {
      group: group,
      merchant: merchant,
    },
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const bulkPaymentStatus = (key, group, merchant) => {
  return axios({
    method: 'GET',
    url: `${paymentStatusUrl}`,
    params: {
      group: group,
      merchant: merchant,
    },
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const createMerchantPaymentGroup = (data, key) => {
  return axios({
    method: 'POST',
    url: merchantAddBulkPaymentGroup,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const createMerchantPaymentBeneficiary = (data, key) => {
  return axios({
    method: 'POST',
    url: merchantAddBulkBeneficiaryUrl,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const payBulkGroup = (data, key) => {
  return axios({
    method: 'POST',
    url: merchantPayGroupUrl,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const uploadBeneficiariesExcel = (key, merchant, group, data) => {
  return axios({
    method: 'POST',
    url: `${uploadBeneficiariesExcelListUrl}`,
    data,
    params: {
      merchant: merchant,
      group: group,
    },
    headers: {
      publicKey: key,
      accessToken,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteBulkBeneficiaries = (key, beneficiary_id) => {
  return axios({
    method: 'DELETE',
    url: `${deleteBeneficiariesUrl}/${beneficiary_id}/delete`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const deletePaymentGroup = (key, group_id) => {
  return axios({
    method: 'DELETE',
    url: `${deletePaymentGroupUrl}/${group_id}/delete`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const sendCrashReportService = (data) => {
  return axios({
    method: 'POST',
    url: sendCrashReportUrl,
    data,
    headers: {
      publicKey,
    },
  });
};

export const checkEligibilityForLoan = () => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/check-eligibility`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const applyForLoan = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/submit-request`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getLoanInterestRates = (loan_tenure) => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/interest-rates?loan_tenure=${loan_tenure}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const verifyAgggregatorCode = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/verify-aggregator-code`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const verifyGuarantorCode = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/verify-warranter-code`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const fetchLoanHistory = (pageNumber, startDate, endDate) => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/merchant-loan-histories`,
    params: {
      page: pageNumber ?? '1',
      startdate: startDate,
      enddate: endDate,
    },
    headers: {
      publicKey,
      accessToken,
    },
  });
};
export const fetchWarranterLoanRequests = (pageNumber, startDate, endDate) => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/warranter-loan-requests`,
    params: {
      page: pageNumber ?? '1',
      startdate: startDate,
      enddate: endDate,
    },
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const fetchWalletCreditLimit = () => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/wallet-credits-limit-balance`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const approveLoan = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/warranter-approval`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const loanRepayment = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/repayment`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const installmentalLoanRepayment = (data) => {
  return axios({
    method: 'POST',
    url: `${loanUrl}/installmental-loan-repayments`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getNextLoanRepayment = (data) => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/next-loan-repayment`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getVirtualAccountInfo = (merchantid, startDate, endDate, type, failedwebhook, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${getInboundTnxsCountUrl}`,
    params: {
      merchant: merchantid,
      startdate: startDate,
      enddate: endDate,
      type: type,
      failedwebhook: failedwebhook,
      page: pageNumber ?? '1',
    },
  });
};

export const payWithDropin = (data) => {
  return axios({
    method: 'POST',
    url: `${dropinUrl}/cp/icp`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getGuarantorTypes = (data) => {
  return axios({
    method: 'GET',
    url: `${loanUrl}/guarrantor-types`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getInboundTransactionsCount = (merchantid, startDate, endDate, type, failedwebhook, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${getInboundTnxsCountOnlyUrl}`,
    params: {
      merchant: merchantid,
      startdate: startDate,
      enddate: endDate,
      type: type,
      failedwebhook: failedwebhook,
      page: pageNumber ?? '1',
    },
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getAlternateAccountForCashpoint = (data) => {
  return axios({
    method: 'POST',
    url: `${cashpointAlternateAccountURL}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const fetchAlternateCashpointAccountFees = (key, merchantID) => {
  return axios({
    method: 'GET',
    url: `${getAlternateAccountFeesURL}/${merchantID}`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const fetchMerchantCurrentLimit = () => {
  return axios({
    method: 'GET',
    url: `${getMerchantCurrentLimitUrl}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const updateMerchantLimit = (data) => {
  return axios({
    method: 'POST',
    url: `${submitMerchantCurrentLimitUrl}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getMerchantRolesList = (key) => {
  return axios({
    method: 'GET',
    url: getMerchantRolesListUrl,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const getMerchantTeamList = (key) => {
  return axios({
    method: 'GET',
    url: getMerchantTeamListUrl,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const lookupCashpoint = (key, nuban) => {
  return axios({
    method: 'GET',
    url: `${lookupCashpointUrl}/${nuban}`,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const uploadFilesToS3 = (folder, data) => {
  return axios({
    method: 'POST',
    url: uploadFileToS3Url,
    data,
    params: { folder: folder },
    headers: {
      publicKey,
      accessToken,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const addTeamMember = (key, data) => {
  return axios({
    method: 'POST',
    url: addTeamMembersUrl,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};
export const updateTeamMemberRole = (key, data, merchant_id) => {
  return axios({
    method: 'PUT',
    url: `${updateTeamMemberUrl}${merchant_id}/role/update`,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const removeTeamMemberRole = (key, data, merchant_id) => {
  return axios({
    method: 'DELETE',
    url: `${updateTeamMemberUrl}${merchant_id}/role/delete`,
    data,
    headers: {
      publicKey: key,
      accessToken,
    },
  });
};

export const addOutboundCategory = (data) => {
  return axios({
    method: 'POST',
    url: `${addOutboundCategoryUrl}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getTnxCategoryList = (merchantId, page) => {
  return axios({
    method: 'GET',
    url: `${getCategoryListUrl}`,
    headers: {
      publicKey,
      accessToken,
    },
    params: {
      merchant: merchantId,
      page: page,
    },
  });
};

export const submitCustomInboundSrv = (data) => {
  return axios({
    method: 'POST',
    url: `${submitCustomInboundUrl}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const submitIncomeCategorySrv = (data) => {
  return axios({
    method: 'POST',
    url: `${submitIncomeCategoryUrl}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const submitStorefrontWaitlistResponse = (data) => {
  return axios({
    method: 'POST',
    url: storefrontWaitlistUrl,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const generateCashpointTnxsHistory = (data, cashpointId) => {
  return axios({
    method: 'POST',
    url: `${generateCashpointTnxHistoryUrl}/${cashpointId}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getFailedWebOrderTnxs = (page) => {
  return axios({
    method: 'GET',
    url: `${getFailedWebOrderTnxsUrl}`,
    params: {
      page,
    },
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getLivelinessKey = () => {
  return axios({
    method: 'GET',
    url: `${getLivelinessKeyUrl}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getMerchantProducts = (merchantId, pageNumber) => {
  return axios({
    method: 'GET',
    url: `${getMerchantProductCategoryUrl}`,
    params: {
      merchant: merchantId,
      page: pageNumber ?? 1,
    },
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const getAllProducts = () => {
  return axios({
    method: 'GET',
    url: `${getAllCategoriesUrl}`,
    headers: {
      publicKey,
      accessToken,
    },
  });
};

export const addProductCategory = (data) => {
  return axios({
    method: 'POST',
    url: `${addProductCategoryUrl}`,
    data,
    headers: {
      publicKey,
      accessToken,
    },
  });
};
